<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div v-if="!loadingAgency">
              <ValidationObserver v-if="agency" v-slot="{ handleSubmit, invalid, touched }" ref="updateForm">
                <form class="authentication-form" @submit.prevent="handleSubmit(updateAgency)">
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput v-model="agency.owner.first_name" name="first_name" rules="required"
                        label="First Name" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="agency.owner.last_name" name="last_name" rules="required" label="Last Name" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="agency.name" name="company_name" rules="required" label="Company Name" />
                    </div>
                    <div class="col-md-6">
                      <TextInput v-model="agency.owner.email" name="owner.email" rules="required|email" label="Email" />
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <TextInput v-model="agency.whitelabel.domain" name="whitelabel.domain" rules="customurl"
                        label="Custom URL" />
                    </div>
                    <div class="col-md-6">
                      <div class="row">
                        <div class="col-6">
                          <TextInput v-model="agency.subdomain" name="subdomain" rules="required|subdomain"
                            label="Subdomain" />
                        </div>
                        <div class="col-6">
                          <TextInput v-model="agency.subdomain_base" :readonly="true" label="Host" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Subscription plan</label>
                        <select v-model="agency.stripe_plan" class="form-control"
                          :disabled="agency.default_payment_method === null">
                          <option v-for="plan in mainPlans" :key="plan.id" :value="plan.id">{{ plan.name }}</option>
                        </select>
                        <div v-if="!agency.free_fee" class="mt-2 d-flex justify-content-between">
                          <span>Status:
                            <strong :class="{
                              'text-primary':
                                agency.stripe_status === 'active',
                              'text-danger':
                                agency.stripe_status === 'canceled' ||
                                agency.stripe_status === 'past_due',
                            }">{{ agency.stripe_status }}</strong></span>
                          <div v-if="agency.stripe_status !== 'canceled'" class="mt-1">
                            <button class="ml-1 btn btn-danger btn-sm" href="javascript:void;"
                              @click.prevent="cancelSubscription('default')">
                              Cancel subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="col-md-6 mb-4 mt-2">
                        <div class="mt-4 pt-2">
                          <span class="pr-2" style="font-weight:600;">OFF</span>
                          <b-form-checkbox v-model="agency.free_fee" name="check-button" switch inline>
                            ON - Free fee
                          </b-form-checkbox>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Livechat plan</label>
                        <select v-model="agency.livechat_plan" class="form-control"
                          :disabled="agency.default_payment_method === null">
                          <option v-for="plan in livechatPlans" :key="plan.id" :value="plan.id">{{ plan.name }}</option>
                        </select>
                        <div v-if="agency.livechat_status" class="mt-2 d-flex justify-content-between">
                          <span>Status:
                            <strong :class="{
                              'text-primary':
                                agency.livechat_status === 'active',
                              'text-danger':
                                agency.livechat_status === 'canceled' ||
                                agency.livechat_status === 'past_due',
                            }">{{ agency.livechat_status }}</strong></span>
                          <div v-if="agency.livechat_status !== 'canceled'" class="mt-1">
                            <button class="ml-1 btn btn-danger btn-sm" href="javascript:void;"
                              @click.prevent="cancelSubscription('livechat')">
                              Cancel subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mb-4 pb-4">
                      <div class="mt-3 pt-4">
                        <span class="pr-2" style="font-weight:600;">OFF</span>
                        <b-form-checkbox v-model="agency.is_active_livechat" name="check-button" switch inline>
                          ON - Livechat
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group">
                        <label>Loop plan</label>
                        <select v-model="agency.loop_plan" class="form-control"
                          :disabled="agency.default_payment_method === null">
                          <option v-for="plan in loopPlans" :key="plan.id" :value="plan.id">{{ plan.name }}</option>
                        </select>
                        <div v-if="agency.loop_status" class="mt-2 d-flex justify-content-between">
                          <span>Status:
                            <strong :class="{
                              'text-primary':
                                agency.loop_status === 'active',
                              'text-danger':
                                agency.loop_status === 'canceled' ||
                                agency.loop_status === 'past_due',
                            }">{{ agency.loop_status }}</strong></span>
                          <div v-if="agency.loop_status !== 'canceled'" class="mt-1">
                            <button class="ml-1 btn btn-danger btn-sm" href="javascript:void;"
                              @click.prevent="cancelSubscription('loop')">
                              Cancel subscription
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 mb-4 pb-4">
                      <div class="mt-3 pt-4">
                        <span class="pr-2" style="font-weight:600;">OFF</span>
                        <b-form-checkbox v-model="agency.is_active_loop" name="check-button" switch inline>
                          ON - Loop
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-6 mt-2 pt-4 mb-2">
                      <div class="mt-2">
                        <span class="pr-2" style="font-weight:600;">OFF</span>
                        <b-form-checkbox v-model="agency.use_integration" name="check-button" switch inline>
                          ON - Integration Feature
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-6 mt-2 pt-4 mb-2">
                      <div class="mt-2">
                        <span class="pr-2" style="font-weight:600;">OFF</span>
                        <b-form-checkbox v-model="agency.is_enable_import_contact" name="check-button" switch inline>
                          ON - Import contact
                        </b-form-checkbox>
                      </div>
                    </div>
                    <div class="col-md-6 mt-2 pt-4 mb-4 pb-4">
                      <div class="mt-2">
                        <span class="pr-2" style="font-weight:600;">OFF</span>
                        <b-form-checkbox v-model="agency.use_vote_campaign" name="check-button" switch inline>
                          ON - Vote Campaign
                        </b-form-checkbox>
                      </div>
                    </div>
                  </div>
                  <b-form-group>
                    <div class="d-flex justify-content-between align-items-center">
                      <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
                        <b-spinner v-if="loadingUpdate" small />
                        <span v-else>Update</span>
                      </b-button>

                      <b-button variant="primary" :disabled="loadingImpersonate" @click="impersonate">
                        <b-spinner v-if="loadingImpersonate" small />
                        <span v-else>Log in <i class="uil uil-sign-out-alt"></i></span>
                      </b-button>
                    </div>
                  </b-form-group>
                </form>
              </ValidationObserver>
            </div>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h5 class="h5">Billing</h5>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit, invalid, touched }">
              <form @submit.prevent="handleSubmit(updateBilling)">
                <div class="row">
                  <div class="col-md-3">
                    <TextInput v-model="settings.cost_per_sms" type="number" label="Cost Per SMS($)" name="costPerSms"
                      rules="required" />
                  </div>
                  <div class="col-md-3">
                    <TextInput v-model="settings.cost_per_mms" type="number" label="Cost Per MMS($)" name="costPerMms"
                      rules="required" />
                  </div>
                  <div class="col-md-3">
                    <TextInput v-model="settings.cost_per_call" type="number" label="Cost Per Minute($)"
                      name="costPerCall" rules="required" />
                  </div>
                  <div class="col-md-3">
                    <TextInput v-model="settings.outbound_carrier_fee" type="number" label="Outbound Carrier Fee($)"
                      name="outboundCarrierFee" rules="required" />
                  </div>
                  <div class="col-md-3">
                    <TextInput v-model="settings.cost_number_provision" type="number" label="Number Provisioning($)"
                      name="numberProvisioning" rules="required" />
                  </div>
                </div>
                <b-form-group>
                  <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else>Update</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>

    <h5 class="h5">Integrations</h5>
    <div class="card">
      <div class="card-body">
        <div class="row mb-4">
          <div class="col-md-12">
            <h5 class="h5">Twilio Config</h5>
          </div>
          <div class="col-md-12">
            <div v-if="twilioConfig" class="row">
              <div class="col-md-6">
                <TextInput v-model="twilioConfig.sid" label="Account sid" :readonly="true" />
              </div>
              <div class="col-md-6">
                <TextInput v-model="twilioConfig.token" label="Auth token" :readonly="true" />
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-button variant="primary" :disabled="loadingUpdate" type="button"
                  @click="showTwilioConfigModal = true">
                  <b-spinner v-if="loadingUpdate" small />
                  <span v-else><i class="uil uil-upload-alt"></i> {{ twilioConfig ? 'Update' : 'Set' }} Twilio Config</span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-4">
          <div class="col-md-12">
            <h5 class="h5">SendGrid</h5>
          </div>
          <div class="col-md-12">
            <div v-if="sendgridIntegration" class="row">
              <div class="col-md-6">
                <TextInput v-model="sendgridIntegration.api_key" label="Api Key" :readonly="true" />
              </div>
              <div class="col-md-6">
                <TextInput v-model="sendgridIntegration.options.from_email" label="From Email" :readonly="true" />
              </div>
              <div class="col-md-6 mb-4">
                <label>Enabled</label>
                <b-form-checkbox v-model="sendgridIntegration.active" name="check-button" switch
                  @change="handleChangeActive('sendgrid')">
                </b-form-checkbox>
              </div>
            </div>
            <div class="row">
              <div class="col-md-12">
                <b-button variant="primary" :disabled="loadingUpdate" type="button"
                  @click="showSendgridIntegrationModal = true">
                  <b-spinner v-if="loadingUpdate" small />
                  <span v-else><i class="uil uil-upload-alt"></i> {{ sendgridIntegration ? 'Update' : 'Set' }}
                    SendGrid Config</span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="passwordForm">
              <form class="authentication-form" @submit.prevent="handleSubmit(changePassword)">
                <div class="row">
                  <div class="col-md-6">
                    <TextInput v-model="password.password" name="password" rules="required|confirmed:confirm_password"
                      label="Password" type="password" />
                  </div>
                  <div class="col-md-6">
                    <TextInput v-model="password.password_confirmation" vid="confirm_password" name="confirm_password"
                      rules="required" label="Confirm Password" type="password" />
                  </div>
                </div>
                <b-form-group>
                  <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
                    <b-spinner v-if="loadingUpdate" small />
                    <span v-else>Change</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showTwilioConfigModal" title="Set Twilio Credential" size="lg" hide-footer>
      <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="twilioForm">
        <form class="twilio-form" @submit.prevent="handleSubmit(updateTwilio)">
          <div class="row">
            <div class="col-md-6">
              <TextInput v-model="twilio.sid" name="TwilioSid" label="Account sid" />
            </div>
            <div class="col-md-6">
              <TextInput v-model="twilio.token" name="TwilioToken" label="Auth token" />
            </div>
          </div>
          <div class="modal-footer">
            <b-button variant="light" @click="showTwilioConfigModal = false">Close</b-button>
            <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
              <b-spinner v-if="loadingUpdate" small />
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
    <b-modal v-model="showSendgridIntegrationModal" title="Set SendGrid Credential" size="lg" hide-footer>
      <ValidationObserver v-slot="{ handleSubmit, invalid, touched }" ref="sendgridForm">
        <form class="sendgrid-form" @submit.prevent="handleSubmit(updateSendgrid)">
          <div class="row">
            <div class="col-md-12">
              <TextInput v-model="sendgrid.api_key" name="apiKey" label="Api Key" />
            </div>
            <div class="col-md-12">
              <TextInput v-model="sendgrid.options.from_email" type="email" name="fromEmail" label="From Email" />
            </div>
          </div>
          <div class="modal-footer">
            <b-button variant="light" @click="showSendgridIntegrationModal = false">Close</b-button>
            <b-button variant="primary" :disabled="loadingUpdate || (invalid && touched)" type="submit">
              <b-spinner v-if="loadingUpdate" small />
              <span v-else>Update</span>
            </b-button>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>

export default {
  components: {
  },

  data() {
    return {
      loadingAgency: false,
      agency: null,
      loadingUpdate: false,
      subscribed: false,
      twilio: {
      },
      sendgrid: {
        options: {
        },
      },
      password: {
        password: '',
        password_confirmation: '',
      },
      settings: {
      },
      loadingImpersonate: false,
      showTwilioConfigModal: false,
      showSendgridIntegrationModal: false,
    }
  },

  computed: {
    twilioConfig() {
      return this.agency && this.agency.twilioConfig
    },

    sendgridIntegration() {
      return this.agency && this.agency.sendgridIntegration
    },

    plans() {
      return this.$store.getters['subscription/allPlans'] || []
    },

    mainPlans() {
      return this.plans
    },

    livechatPlans() {
      return this.plans.filter(p => p.is_livechat_plan)
    },

    loopPlans() {
      return this.plans.filter(p => p.is_loop_plan)
    },
  },

  mounted() {
    if (!this.$store.getters['subscription/allPlans']) {
      this.$store.dispatch('subscription/getAllPlans')
    }

    this.getAgency()
    this.initData();
  },

  methods: {
    initData() {
      this.twilio = {
        sid: null,
        token: null,
      }
      this.sendgrid = {
        options: {
        },
      }
    },

    updateAgency() {
      this.loadingUpdate = true

      this.$store
        .dispatch('agency/update', this.agency)
        .then((agency) => {
          this.agency = agency
          this.loadingUpdate = false
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.updateForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    getAgency() {
      this.loadingAgency = true

      this.$store
        .dispatch('agency/find', this.$route.params.id)
        .then((agency) => {
          this.subscribed = agency.stripe_plan !== null
          this.agency = agency
          this.settings = agency.owner.settings
          this.loadingAgency = false
        })
        .catch(() => {
          this.$router.push({ name: 'admin.agencies.index' })
          this.loadingAgency = false
        })
    },

    cancelSubscription(section = 'default') {
      this.loadingUpdate = true

      this.$store
        .dispatch('subscription/cancel', { userId: this.agency.owner.id, section: section })
        .then((agency) => {
          if (section === 'livechat') {
            this.agency.livechat_plan = null
            this.agency.livechat_status = 'canceled'
          } else {
            this.agency.stripe_plan = null
            this.agency.stripe_status = 'canceled'
          }

          this.subscribed = false
          this.loadingUpdate = false
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    changePassword() {
      this.loadingUpdate = true

      this.$store
        .dispatch('user/changePassword', {
          ...this.password,
          user_id: this.agency.owner.id,
        })
        .then(() => {
          this.password = {}
          this.loadingUpdate = false
          this.$refs.passwordForm.reset()
        })
        .catch((err) => {
          if (err.response.data.errors) {
            this.$refs.passwordForm.setErrors(err.response.data.errors)
          }
          this.loadingUpdate = false
        })
    },

    impersonate() {
      this.loadingImpersonate = true
      const user = this.agency.owner
      if (user) {
        this.$store
          .dispatch('auth/impersonate', user.id)
          .then((res) => {
            this.$store
              .dispatch('auth/getProfile')
              .then((user) => {
                this.$router.push('/' + user.role.toLocaleLowerCase())
                this.loadingImpersonate = false
              })
              .catch(() => {
                this.loadingImpersonate = false
              })
          })
          .catch(() => {
            this.loadingImpersonate = false
          })
      }
    },

    updateTwilio() {
      this.loadingUpdate = true

      this.$store
        .dispatch('agency/adminUpdateTwilio', {
          agencyId: this.agency.id,
          twilio: this.twilio,
        })
        .then((res) => {
          if (res.token)
            this.agency.twilioConfig = res
          else
            this.agency.twilioConfig = null
          this.loadingUpdate = false
          this.showTwilioConfigModal = false
          this.initData();
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    updateSendgrid() {
      this.loadingUpdate = true

      this.$store
        .dispatch('agency/adminUpdateSendgrid', {
          agencyId: this.agency.id,
          sendgrid: this.sendgrid,
        })
        .then((res) => {
          if (res.api_key)
            this.agency.sendgridIntegration = res
          else
            this.agency.sendgridIntegration = null
          this.loadingUpdate = false
          this.showSendgridIntegrationModal = false
          this.initData();
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    updateBilling() {
      this.loadingUpdate = true

      this.$store
        .dispatch('agency/adminUpdateBilling', {
          agencyId: this.agency.id,
          settings: this.settings,
        })
        .then(() => {
          this.loadingUpdate = false
        })
        .catch(() => {
          this.loadingUpdate = false
        })
    },

    handleChangeActive(type) {
      switch (type) {
        case 'sendgrid':
          if (this.sendgridIntegration)
            this.changeIntegrationStatus(this.sendgridIntegration.id)
          break;
        default:
          break;
      }
    },

    changeIntegrationStatus(id) {
      this.$store
        .dispatch('integration/toggleStatus', id)
        .then((integration) => {
        })
        .catch(() => {
        })
    }
  },
}
</script>
